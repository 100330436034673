import styles from "./case-info-block.module.css"

const CaseInfoBlock = ({
    backgroundColor,
    titleText,
    titleImgPath,
    isImgOutContainer,
    itemKey,
    infoList,
    isWeb = false
}) => {
    return (
        <div className={`${styles.case_info_block} ${isWeb && styles.web} ${itemKey} ${isImgOutContainer && styles.img_out}`} style={{ backgroundColor: backgroundColor }}>
            <div className={`${styles.content} container`}>
                <div className={styles.title_block}>
                    {titleText && (
                        <p className={styles.title}>{titleText}</p>
                    )}

                    {titleImgPath && (
                        <div className={styles.img_block}>
                            <img src={titleImgPath} alt="illustration" />
                        </div>
                    )}
                </div>

                <div className={styles.info_block}>
                    {infoList?.map((item, index) => {
                        return (
                            <div key={itemKey + index} className={styles.info_item}>
                                <p className={styles.title}>{item.title}</p>
                                <p className={styles.info}>{item.info}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default CaseInfoBlock