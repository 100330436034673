import { Button, Checkbox, Form, Upload } from "antd"
import { useState } from "react"
import { sendFormData } from "../../api/contact-form-api"
import CustomInput from "./components/custom-input/custom-input"
import { messageSuccess } from "../../antd-message/antd-message"
import styles from "./contact-us-form.module.css"

const ContactUsForm = () => {
    const [form] = Form.useForm()

    const [informed, setInformed] = useState(false)
    const [agree, setAgree] = useState(false)

    const toBase64 = (file) => new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => {
			resolve(reader.result.substring(reader.result.indexOf(",") + 1))
		}
		reader.onerror = (error) => reject(error)
	})

	const getBase64Files = async (filesList) => {
		return Promise.all(filesList.map(async (file) => {
			const fileBase64 = await toBase64(file.originFileObj)

			return {
				body: fileBase64,
				name: file.name
			}
		})).then((files) => {
			return files
		})
	}

    const handleFinish = async (data) => {
        const files = data.files?.fileList || []

        const filesBase64 = (files.length > 0 && await getBase64Files(files)) || []

        const newData = {
            ...data,
            files: filesBase64
        }

        sendFormData(newData).then(() => {
            messageSuccess("Your message was successfully sent")

            form.resetFields()
        })
    }

    return (
        <div className={styles.form_block}>
            <Form form={form} onFinish={handleFinish}>
                <div className={styles.form_row}>
                    <Form.Item
                        name="name"
                        rules={[
                            { required: true, message: "This field is required" }
                        ]}
                    >
                        <CustomInput name="*NAME" />
                    </Form.Item>

                    <Form.Item
                        name="email"
                        rules={[
                            { required: true, message: "This field is required" },
                            { type: "email", message: "Please enter valid email" }
                        ]}
                    >
                        <CustomInput name="*EMAIL" />
                    </Form.Item>
                </div>

                <div className={styles.form_row}>
                    <Form.Item
                        name="phone"
                        rules={[
                            { required: true, message: "This field is required" }
                        ]}
                    >
                        <CustomInput name="*PHONE" />
                    </Form.Item>

                    <Form.Item
                        name="country"
                        rules={[
                            { required: true, message: "This field is required" }
                        ]}
                    >
                        <CustomInput name="*COUNTRY" />
                    </Form.Item>
                </div>

                <div className={styles.message_input}>
                    <Form.Item
                        name="message"
                        rules={[
                            { required: true, message: "This field is required" }
                        ]}
                    >
                        <CustomInput name="*MESSAGE" />
                    </Form.Item>
                </div>

                <div className={styles.upload_block}>
                    <Form.Item name="files" valuePropName="files">
                        <Upload beforeUpload={() => { return false }}>
                            <Button className={styles.upload_button}>
                                <img src="/img/attached-file-icon.svg" alt="upload-icon" />
                                Upload file
                            </Button>
                        </Upload>
                    </Form.Item>
                </div>

                <div className={styles.send_block}>
                    <div className={styles.checkboxes_block}>
                        <div className={styles.checkbox_item}>
                            <div className={styles.checkbox}>
                                <Form.Item name="informed" valuePropName="checked">
                                    <Checkbox onChange={(e) => setInformed(e.target.checked)} />
                                </Form.Item>
                            </div>

                            <p className={styles.checkbox_text}>I am informed about processing of my personal data and the right to withdraw my consent</p>
                        </div>

                        <div className={styles.checkbox_item}>
                            <div className={styles.checkbox}>
                                <Form.Item name="agree" valuePropName="checked">
                                    <Checkbox onChange={(e) => setAgree(e.target.checked)} />
                                </Form.Item>
                            </div>

                            <p className={styles.checkbox_text}>I agree to be included into Techcore's IT systems for the purpose of being contacted</p>
                        </div>
                    </div>

                    <Button
                        onClick={() => {form.submit()}}
                        className={styles.send_button}
                        disabled={!(informed && agree)}
                    >
                        Send message
                    </Button>
                </div>

                <p className={styles.info_text}>
                    By submitting completed “Contact Us” form, your personal data will be processed by Techcore and its subsidiary entities worldwide. Please read our Privacy Notice for more information. If you have any questions regarding your rights or would subsequently decide to withdraw your consent, please send your request to us.
                </p>
            </Form>
        </div>
    )
}

export default ContactUsForm