import { useEffect } from "react"
import CaseInfoBlock from "../shared/case-info-block/case-info-block"
import CaseMainBlock from "../shared/case-main-block/case-main-block"
import ContactUs from "../shared/contact-us/contact-us"
import MainBlock from "../shared/main-block/main-block"
import styles from "./web-apps.module.css"
import { useLocation } from "react-router-dom"

const WebApps = () => {
    const handleClickScroll = () => {
        const element = document.getElementById("chemwatch")

        if (element) {
            element.scrollIntoView({ behavior: "smooth" })
        }
    }

    const { state } = useLocation()
    const { blockName } = state || {}

    useEffect(() => {
        const el = document.getElementById(blockName)

        if (el) {
            el.scrollIntoView(true)
        }
    }, [blockName])

    return (
        <div className={styles.web_apps_block}>
            <div className={styles.main_block}>
                <MainBlock
                    title="Web Development"
                    description="Case Studies"
                    imgPath="/img/main-illustration-web-apps.webp"
                    backgroundImgPath="/img/main-background-dots-figure.svg"
                    onMoreButtonClick={handleClickScroll}
                    className="mobile-web-apps"
                />
            </div>

            <div id="chemwatch"></div>
            <CaseMainBlock
                withTopMenu={false}
                backgroundColor="linear-gradient(116.98deg, #293771 0%, #1D2245 80.68%)"
                backgroundImagePath="/img/main-background-chemwatch.svg"
                imgPath="/img/case-illustration-chemwatch.png"
                infoText="The World's leader in Chemical Safety Management"
                caseName="Chemwatch"
                cases={[
                    { amount: 11, text1: "years", text2: "of cooperation" },
                    { amount: 23, text2: "engineering team" },
                    { amount: 6, text1: "clients", text2: "of the top 20 Fortune 500" }
                ]}
                solutions={["Web application", "QA", "Security audit", "Product Design", "Oracle", "ASP.NET Core", "ASP.NET Web API"]}
            />

            <CaseInfoBlock
                titleImgPath="/img/info-illustration-chemwatch.png"
                isImgOutContainer={true}
                itemKey="chemwatch-web"
                infoList={[
                    {
                        title: "About",
                        info: (
                            <span>
                                Chemwatch is a leading provider of comprehensive chemical management software, offering access to the world’s largest database of chemicals. Their products are trusted by over 7,000 chemical manufacturers, users and suppliers worldwide, including 6 of the top 20 Fortune 500 companies.
                            </span>
                        )
                    },
                    {
                        title: "Goal & Deliverables",
                        info: (
                            <span>
                                Chemwatch was seeking a long-term partner with exceptional technical expertise in web and mobile app development, quality assurance processes. The primary objective was to revamp their outdated solution, enhance its scalability, and elevate the user interface and experience to a new level. The project required a comprehensive approach that addressed various technical and design challenges. The goal was to create a modern, efficient, and extensible solution that could meet the needs of Chemwatch’s clients and stakeholders.
                                <br /><br />
                                Our team developed a comprehensive web portal that includes a variety of modules, such as chemical registration and management, Safety Data Sheet management, risk assessments management, approvals management, inventory management, and chemical labelling. The web portal’s interface supports 47 languages.
                            </span>
                        )
                    }
                ]}
                isWeb={true}
            />

            <div id="pagos"></div>
            <CaseMainBlock
                withTopMenu={false}
                backgroundColor="linear-gradient(122.07deg, #007AFE -0.26%, #0717A0 105.25%)"
                backgroundImagePath="/img/main-background-pagos.svg"
                imgPath="/img/case-illustration-pagos.png"
                infoText="Intelligent Payment Infrastructure for Commerce"
                caseName="Pagos Solutions"
                cases={[
                    { amount: "1B+", text2: "transactions tracked" },
                    { amount: 25, text2: "engineering team" },
                    { amount: "15+", text2: "PSPs integrated" }
                ]}
                solutions={["Web application", "QA", "Security audit", "Product Design", "AWS", "Amazon Redshift", "Microservices", "Node.js", "React.js", "eCharts.js"]}
            />

            <CaseInfoBlock
                titleImgPath="/img/info-illustration-pagos.png"
                isImgOutContainer={true}
                itemKey="pagos-web"
                infoList={[
                    {
                        title: "About",
                        info: (
                            <span>
                                Pagos Solutions is a startup in the field of payment systems infrastructure. The company is building a data platform and API-based microservices that can be integrated with any payment stack. The ultimate goal is to improve performance and optimize the existing business payment infrastructure.
                            </span>
                        )
                    },
                    {
                        title: "Goal & Deliverables",
                        info: (
                            <span>
                                Our goal was to create a web solution that would help Pagos Solutions' clients to export their payments data from any payment processor and analyze it. The solution needed to provide immediate payment data visualizations, automatic notifications on payment trends or problems.
                                <br /><br />
                                Our team developed a SaaS platform that enables clients to consolidate and control all their payment data, create customized reports and dashboards, monitor trends. The solution includes best-in-class data visualizations, and allows clients to compare their performance to that of peers and benchmark vendor performance. By analyzing key metrics such as authorization rate, decline reasons, chargebacks/disputes, processing costs, interchange fees, and assessments, our solution helps clients optimize their checkout flow, reduce churn, increase approval rates, and manage risk.
                            </span>
                        )
                    }
                ]}
                isWeb={true}
            />

            <div id="eco-credit"></div>
            <CaseMainBlock
                withTopMenu={false}
                backgroundColor="linear-gradient(116.55deg, #32D285 4.54%, #019A50 86.96%)"
                backgroundImagePath="/img/main-background-eco-credit.svg"
                imgPath="/img/case-illustration-eco-credit.png"
                infoText="CO2 Credit MARKETPLACE"
                caseName="EcoCredit"
                cases={[
                    { amount: 4, text1: "months", text2: "to MVP" },
                    { amount: 4, text2: "engineering team" }
                ]}
                solutions={["Web application", "Product Design", "AWS", "Node.js", "React.js"]}
            />

            <CaseInfoBlock
                titleImgPath="/img/info-illustration-eco-credit.png"
                isImgOutContainer={true}
                itemKey="eco-credit-web"
                infoList={[
                    {
                        title: "About",
                        info: (
                            <span>
                                The EcoCredit marketplace is a platform that connects businesses with carbon offset projects to reduce global greenhouse gas emissions, which are mostly caused by businesses. This platform facilitates the purchase and sale of carbon offsets, which are used to compensate for carbon emissions. By participating in this marketplace, businesses can help reduce their carbon footprint and contribute to a more sustainable future.
                            </span>
                        )
                    },
                    {
                        title: "Goal & Deliverables",
                        info: (
                            <span>
                                The primary goal of the project was to deliver the MVP within the next four months. Our team successfully developed and delivered the MVP within the given timeframe. We created a user-friendly interface that made it easy for businesses to participate in the marketplace. We integrated various features such as project profiles, carbon credits, and a carbon calculator to help businesses better understand their carbon footprint. We hope this solution helps businesses take a step towards a more sustainable future by reducing their carbon footprint.
                            </span>
                        )
                    }
                ]}
                isWeb={true}
            />

            <div id="boommy"></div>
            <CaseMainBlock
                withTopMenu={false}
                backgroundColor="linear-gradient(118.8deg, #0098D9 -15.13%, #06AD74 98.55%), linear-gradient(120.42deg, #37C1FD -36.6%, #33D09A 73.72%)"
                backgroundImagePath="/img/main-background-boommy.svg"
                imgPath="/img/case-illustration-boommy.png"
                infoText="FASHION P2P MARKETPLACE"
                caseName="Boommy"
                cases={[
                    { amount: "50k+", text2: "registered users" },
                    { amount: 6, text1: "months", text2: "to MVP" }
                ]}
                solutions={["Web application", "QA", "ASP.NET MVC", "MS SQL", "jQuery"]}
            />

            <CaseInfoBlock
                titleImgPath="/img/info-illustration-boommy.png"
                isImgOutContainer={true}
                itemKey="boommy-web"
                infoList={[
                    {
                        title: "About",
                        info: (
                            <span>
                                Boommy is an e-commerce marketplace designed for trendy young people. It combines a social network and a platform for sharing things. Each user has the opportunity to find and buy things they like, exchange clothes, sell boring or unsuitable wardrobe items. In addition to perfection, here you can chat with friends, chat with stylists, meet interesting and fashionable people.
                            </span>
                        )
                    },
                    {
                        title: "Goal & Deliverables",
                        info: (
                            <span>
                                Our main goal was to create an ecosystem that provided convenience and interest for stylists, fashion bloggers, designers, clothing sellers, and other participants in the fashion industry. To achieve this, our team developed a user-friendly interface that allowed for easy navigation and social interaction among users.
                                <br /><br />
                                Our deliverables included the development and launch of the Boommy platform. We ensured that it was responsive and optimized for various devices, making it accessible to a wider range of users. Additionally, we integrated various features such as a messaging system, search filters, and secure payment gateways, to enhance the user experience.
                            </span>
                        )
                    }
                ]}
                isWeb={true}
            />

            <div id="trakopolis"></div>
            <CaseMainBlock
                withTopMenu={false}
                backgroundColor="linear-gradient(120.12deg, #756362 0%, #20243F 112.92%)"
                backgroundImagePath="/img/main-background-eco-credit.svg"
                imgPath="/img/case-illustration-trakopolis.png"
                infoText="real-time tracking and management of corporate assets"
                caseName="Trakopolis"
                cases={[
                    { amount: "100k+", text2: "events tracked everyday" },
                    { amount: "10+", text2: "integrations with sensors" }
                ]}
                solutions={["Web solution", "Mobile app", "QA", "Security audit"]}
            />

            <CaseInfoBlock
                titleImgPath="/img/info-illustration-trakopolis.png"
                isImgOutContainer={true}
                itemKey="trakopolis-web"
                infoList={[
                    {
                        title: "About",
                        info: (
                            <span>
                                Trakopolis is a cloud-based platform that provides solutions for real-time tracking, data analysis and management of corporate assets such as equipment, devices, vehicles and workers.
                            </span>
                        )
                    },
                    {
                        title: "Goal & Deliverables",
                        info: (
                            <span>
                                Our goal was to create a robust and scalable platform that could handle large volumes of data and provide actionable insights in real-time. To achieve this, we designed and implemented a complex architecture that included data ingestion, storage, and analysis using advanced algorithms. We also integrated various sensors and devices into the platform, allowing for real-time monitoring and tracking of assets.
                                <br /><br />
                                Our deliverables included a fully functional cloud-based platform that allowed businesses to track and manage their assets in real-time. The platform included a customizable dashboard, advanced reporting capabilities, and real-time alerts and notifications. We also provided ongoing support and maintenance services to ensure the platform remained up-to-date and functional.
                            </span>
                        )
                    }
                ]}
                isWeb={true}
            />

            <div id="bee-hive"></div>
            <CaseMainBlock
                withTopMenu={false}
                backgroundColor="linear-gradient(116.98deg, #293771 0%, #1D2245 80.68%)"
                backgroundImagePath="/img/main-background-chemwatch.svg"
                imgPath="/img/case-illustration-bee-hive.png"
                infoText="The World's leader in Chemical Safety Management"
                caseName="BeeHive by Chemwatch"
                cases={[
                    { amount: 11, text1: "years", text2: "of cooperation" },
                    { amount: 23, text2: "engineering team" },
                    { amount: 6, text1: "clients", text2: "of the top 20 Fortune 500" }
                ]}
                solutions={["Web application", "Product Design", "AWS", "node.js", "react.js", "PostgreSQL", "Amazon Aurora"]}
            />

            <CaseInfoBlock
                titleImgPath="/img/info-illustration-bee-hive.png"
                isImgOutContainer={true}
                itemKey="bee-hive-web"
                infoList={[
                    {
                        title: "About",
                        info: (
                            <span>
                                Chemwatch is a leading provider of comprehensive chemical management software, offering access to the world’s largest database of chemicals. Their products are trusted by over 7,000 chemical manufacturers, users and suppliers worldwide, including 6 of the top 20 Fortune 500 companies.
                            </span>
                        )
                    },
                    {
                        title: "Goal & Deliverables",
                        info: (
                            <span>
                                Beehive’s mission was to help Chemwatch upgrade their existing solution, enhance its scalability, migrate it to a serverless architecture on AWS stack, reduce costs of database licences. The project required a comprehensive approach to address various technical challenges, such as data management and migration, microservices architecture design, and scalability.
                                <br /><br />
                                By utilizing a serverless architecture, we were able to achieve high scalability, cost-effectiveness, and reliability. The web portal’s interface was designed with a user-centric approach, supporting 47 languages. The solution was rigorously tested through automated unit tests, integration tests, and end-to-end tests, ensuring high quality and minimizing the risk of errors. The result was a comprehensive, modern, and efficient solution that met Chemwatch’s needs and exceeded their expectations.
                            </span>
                        )
                    }
                ]}
                isWeb={true}
            />

            <div id="m-stable"></div>
            <CaseMainBlock
                withTopMenu={false}
                backgroundColor="linear-gradient(116.73deg, #9F2FFF 0%, #5700A2 73.63%)"
                backgroundImagePath="/img/main-background-m-stable.svg"
                imgPath="/img/case-illustration-m-stable.png"
                infoText="Web-dashboard for in-depth stablecoin analytics"
                caseName="mStable"
                cases={[
                    { amount: 16, text2: "metrics tracked" },
                    { amount: 4, text1: "months", text2: "to release" }
                ]}
                solutions={["Web application", "Product Design", "Vue.js", "Python", "node.js", "AWS"]}
            />

            <CaseInfoBlock
                titleImgPath="/img/info-illustration-m-stable.png"
                isImgOutContainer={true}
                itemKey="m-stable-web"
                infoList={[
                    {
                        title: "About",
                        info: (
                            <span>
                                mStable is a stablecoin designed for decentralized finance, providing stability and liquidity to the DeFi ecosystem.
                            </span>
                        )
                    },
                    {
                        title: "Goal & Deliverables",
                        info: (
                            <span>
                                The goal of the mStable project is to develop a user-friendly analytics dashboard for mStable stablecoin family that provides accurate and up-to-date information on stablecoin total value locked (TVL) and enables informed decision-making for users.
                                <br /><br />
                                The team has successfully released the functionality of the dashboard in a short period of four months. The dashboard includes features such as tracking stablecoin TVL, analyzing metrics, and monitoring trends, providing a comprehensive overview of the stablecoin market for users.
                            </span>
                        )
                    }
                ]}
                isWeb={true}
            />

            <ContactUs />
        </div>
    )
}

export default WebApps