import ContactUs from "../shared/contact-us/contact-us"
import Footer from "../shared/footer/footer"
import styles from "./services.module.css"
import IndustryServices from "../shared/industry-services/industry-services"

const Services = () => {
    return (
        <div className={styles.services_page}>
            <IndustryServices
                title="Services"
                casesColumnsInfo={[
                    { cases: [ "Web Development", "Mobile Development", "Cross-Platform Development", "Custom Software Development", "Startups & MVP Development", "Staff Augmentation", "Dedicated Team" ] },
                    { cases: [ "Discovery Phase", "UX/UI Design", "Product Design", "Business Analysis", "Solution Architecture", "Project Management", "Quality Assurance" ] },
                    { cases: [ "Data Science", "Cloud Development", "IoT Development Services", "SaaS", "Database", "BI & Big Data Consulting", "Application Maintenance & Support Services" ] }
                ]}
            />

            <ContactUs />
        </div>
    )
}

export default Services