import { NavLink } from "react-router-dom"

const NavLinkScroll = ({ link, onClick, children, ...props }) => {
    const handleClick = () => {
        onClick && onClick()

        window.scrollTo(0, 0)
    }

    return (
        <NavLink to={link} onClick={() => handleClick()} {...props}>
            {children}
        </NavLink>
    )
}

export default NavLinkScroll