import { Input } from "antd"
import { useState } from "react"
import styles from "./custom-input.module.css"

const CustomInput = ({ value, onChange, name }) => {
    const [isFocused, setIsFocused] = useState(false)

    return (
        <div className={`${styles.custom_input} ${isFocused && styles.focused} ${value && styles.filled}`}>
            <p className={styles.input_title}>{name}</p>
            <Input
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                onChange={(e) => onChange(e.target.value)}
                className={styles.input}
                placeholder={name}
            />
        </div>
    )
}

export default CustomInput