import ContactUs from "../shared/contact-us/contact-us"
import Footer from "../shared/footer/footer"
import IndustryServices from "../shared/industry-services/industry-services"
import styles from "./technologies.module.css"

const Technologies = () => {
    return (
        <div className={styles.technologies_page}>
            <IndustryServices
                title="Technologies"
                casesColumnsInfo={[
                    { title: "Front-End", cases: [ "React.js", "Angular", "Vue.js", "Nest.js", "ASP.NET Core" ] },
                    { title: "Back-end", cases: [ ".NET", "PHP", "Python", "Node.js" ] },
                    { title: "Mobile", cases: [ "Android", "iOS", "Flutter", "Xamarin", "Xamarin.Forms", ".NET MAUI" ] },
                    { title: "Cloud", cases: [ "AWS", "MS Azure", "Google Cloud" ] },
                    { title: "Data", cases: [ "Oracle", "MS SQL", "PostgreSQL", "DynamoDb", "MongoDb", "AWS Redshift", "Apache AirFlow", "Apache Kafka" ] }
                ]}
            />

            <ContactUs />
        </div>
    )
}

export default Technologies