import ContactUsForm from "./conctact-us-form/contact-us-form"
import styles from "./contact-us.module.css"

const ContactUs = ({ isForTopMenu = false }) => {
    return (
        <div className={`${styles.contact_us_block} ${isForTopMenu && styles.for_top_menu}`}>
            <div className={styles.wrapper}>
                <div className={styles.title_block}>
                    <h3 className={styles.title}>{(isForTopMenu && "Let's talk") || "Contact us"}</h3>
                    {!isForTopMenu && (
                        <p className={styles.email}>inbox@techcoresoft.com</p>
                    )}
                </div>

                <div className={styles.form_block}>
                    <ContactUsForm />
                </div>
            </div>
        </div>
    )
}

export default ContactUs