import { Button } from "antd"
import { useNavigate } from "react-router-dom"
import styles from "./main-page-case.module.css"
import * as Scroll from 'react-scroll';

const MainPageCase = ({
    backgroundColor = "linear-gradient(129.55deg, #004FD5 -8.39%, #003082 82.91%)",
    backgroundImagePath,
    infoText,
    caseName,
    classname,
    cases,
    imgPath,
    imgPosition = "center",
    link
}) => {
    const navigate = useNavigate()
    const scroller = Scroll.scroller

    const goToAnotherPageAndScroll = async ({ page, block }) => {
        await navigate(page)
        await scroller.scrollTo(block)
    }

    return (
        <Button onClick={() => {goToAnotherPageAndScroll(link)}} className={`${styles.case_block} ${styles[classname]}`} style={{ backgroundImage: backgroundColor }}>
            <div className={styles.wrapper} style={{ backgroundImage: `url(${backgroundImagePath})` }}>
                <div className={styles.info_block}>
                    <p className={styles.info}>{infoText}</p>
                    <h3 className={styles.case_name}>{caseName}</h3>

                    <div className={styles.cases_block}>
                        {cases?.map(item => {
                            return (
                                <div key={item.text2} className={styles.case}>
                                    <div className={styles.title_block}>
                                        <p>{item.amount}</p>
                                        <p>{item.text1 || ""}</p>
                                    </div>

                                    <p className={styles.case_info}>{item.text2}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className={`${styles.img_block} ${styles[imgPosition]}`}>
                    <img src={imgPath} alt="illustration" />
                </div>
            </div>
        </Button>
    )
}

export default MainPageCase