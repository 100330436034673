import { Button, Menu, Modal, Popover } from "antd"
import { useState } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import ContactUs from "../contact-us/contact-us"
import styles from "./top-menu.module.css"
import NavLinkScroll from "../nav-link-scroll/nav-link-scroll"

const TopMenu = () => {
    const navigate = useNavigate()

    const [isContactUsModalVisible, setIsContactUsModalVisible] = useState(false)
    const [isCaseStudiesPopoverVisible, setIsCaseStudiesPopoverVisible] = useState(false)
    const [collapsed, setCollapsed] = useState(false)

    const toggleCollapsed = () => {
        setCollapsed(!collapsed)
    }

    const caseStudiesPopoverContent = (
        <div className={styles.popover_content}>
            <NavLinkScroll
                to="/web-apps"
                onClick={() => setIsCaseStudiesPopoverVisible(false)}
                className={({ isActive }) => {
                    return isActive ? `${styles.popover_button} ${styles.active}` : styles.popover_button
                }}
            >
                Web apps
                <span className={styles.border_bottom} />
            </NavLinkScroll>

            <NavLinkScroll
                to="/mobile-apps"
                onClick={() => setIsCaseStudiesPopoverVisible(false)}
                className={({ isActive }) => {
                    return isActive ? `${styles.popover_button} ${styles.active}` : styles.popover_button
                }}
            >
                Mobile apps
                <span className={styles.border_bottom} />
            </NavLinkScroll>
        </div>
    )

    if (isContactUsModalVisible) {
        return (
            <Modal
                open={isContactUsModalVisible}
                className={styles.contact_us_modal}
                closable={false}
            >
                <div className={styles.content}>
                    <Button onClick={() => setIsContactUsModalVisible(false)} className={`${styles.hamburger_button} ${styles.close_icon_button} ${isContactUsModalVisible && styles.collapsed}`}>
                        <span className={styles.line}></span>
                    </Button>

                    <ContactUs isForTopMenu={true} />
                </div>
            </Modal>
        )
    }

    return (
        <div className={styles.top_menu}>
            <div className="container">
                <div className={styles.content}>
                    <Button className={styles.logo_button} onClick={() => navigate("/")}>
                        <img src="/img/logo.svg" alt="logo-icon" />
                    </Button>

                    <div className={styles.navigation_block}>
                        <div className={styles.navigation}>
                            <Popover
                                arrow={false}
                                open={isCaseStudiesPopoverVisible}
                                content={caseStudiesPopoverContent}
                                overlayClassName={styles.popover}
                                placement="bottomLeft"
                                trigger="hover"
                                onOpenChange={(state) => {
                                    setIsCaseStudiesPopoverVisible(state)
                                }}
                            >
                                <div className={isCaseStudiesPopoverVisible ? `${styles.navigation_button} ${styles.active}` : styles.navigation_button}>
                                    Case Studies
                                    <span className={styles.border_bottom} />
                                </div>
                            </Popover>

                            <NavLinkScroll
                                to="/industries"
                                className={({ isActive }) => {
                                    return isActive ? `${styles.navigation_button} ${styles.active}` : styles.navigation_button
                                }}
                            >
                                Industries
                                <span className={styles.border_bottom} />
                            </NavLinkScroll>

                            <NavLinkScroll
                                to="/services"
                                className={({ isActive }) => {
                                    return isActive ? `${styles.navigation_button} ${styles.active}` : styles.navigation_button
                                }}
                            >
                                Services
                                <span className={styles.border_bottom} />
                            </NavLinkScroll>

                            <NavLinkScroll
                                to="/technologies"
                                className={({ isActive }) => {
                                    return isActive ? `${styles.navigation_button} ${styles.active}` : styles.navigation_button
                                }}
                            >
                                Technologies
                                <span className={styles.border_bottom} />
                            </NavLinkScroll>
                        </div>

                        <Button onClick={() => setIsContactUsModalVisible(true)} className={styles.get_in_touch_button}>
                            Get in touch
                        </Button>

                        <Button onClick={() => setIsContactUsModalVisible(true)} className={styles.mobile_get_in_touch_button}>
                            <img src="/img/email-icon.svg" alt="email-icon" />
                        </Button>

                        <div className={styles.mobile_navigation}>
                            <Button onClick={() => toggleCollapsed()} className={`${styles.hamburger_button} ${collapsed && styles.collapsed}`}>
                                <span className={styles.line}></span>
                            </Button>

                            <Menu
                                mode="inline"
                                className={`mobile-top-menu-dropdown ${collapsed && "collapsed"}`}
                            >
                                <Menu.Item key="link1">
                                    <NavLinkScroll
                                        to="/web-apps"
                                        onClick={() => toggleCollapsed()}
                                        className={({ isActive }) => {
                                            return isActive ? `${styles.mobile_menu_dropdown_button} ${styles.active}` : styles.mobile_menu_dropdown_button
                                        }}
                                    >
                                        Web apps
                                    </NavLinkScroll>
                                </Menu.Item>
                                <Menu.Item key="link2">
                                    <NavLinkScroll
                                        to="/mobile-apps"
                                        onClick={() => toggleCollapsed()}
                                        className={({ isActive }) => {
                                            return isActive ? `${styles.mobile_menu_dropdown_button} ${styles.active}` : styles.mobile_menu_dropdown_button
                                        }}
                                    >
                                        Mobile apps
                                    </NavLinkScroll>
                                </Menu.Item>
                                <Menu.Item key="link3">
                                    <NavLinkScroll
                                        to="/industries"
                                        onClick={() => toggleCollapsed()}
                                        className={({ isActive }) => {
                                            return isActive ? `${styles.mobile_menu_dropdown_button} ${styles.active}` : styles.mobile_menu_dropdown_button
                                        }}
                                    >
                                        Industries
                                    </NavLinkScroll>
                                </Menu.Item>
                                <Menu.Item key="link4">
                                    <NavLinkScroll
                                        to="/services"
                                        onClick={() => toggleCollapsed()}
                                        className={({ isActive }) => {
                                            return isActive ? `${styles.mobile_menu_dropdown_button} ${styles.active}` : styles.mobile_menu_dropdown_button
                                        }}
                                    >
                                        Services
                                    </NavLinkScroll>
                                </Menu.Item>
                                <Menu.Item key="link5">
                                    <NavLinkScroll
                                        to="/technologies"
                                        onClick={() => toggleCollapsed()}
                                        className={({ isActive }) => {
                                            return isActive ? `${styles.mobile_menu_dropdown_button} ${styles.active}` : styles.mobile_menu_dropdown_button
                                        }}
                                    >
                                        Technologies
                                    </NavLinkScroll>
                                </Menu.Item>
                            </Menu>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopMenu