import styles from "./case-main-block.module.css"

const CaseMainBlock = ({
    backgroundColor,
    backgroundImagePath,
    infoText,
    caseName,
    cases,
    imgPath,
    solutions,
    isMobile
}) => {
    return (
        <div className={`${styles.case_main_block} ${isMobile && styles.mobile_img}`} style={{ backgroundImage: backgroundColor }}>
            <div className={styles.wrapper} style={{ backgroundImage: `url(${backgroundImagePath})` }}>
                <div className={`${styles.content} container`}>
                    <div className={styles.info_block}>
                        <p className={styles.info}>{infoText}</p>
                        <h3 className={styles.case_name}>{caseName}</h3>

                        <div className={styles.cases_block}>
                            {cases?.map(item => {
                                return (
                                    <div key={item.text2} className={styles.case}>
                                        <div className={styles.title_block}>
                                            <p>{item.amount}</p>
                                            <p>{item.text1 || ""}</p>
                                        </div>

                                        <p className={styles.case_info}>{item.text2}</p>
                                    </div>
                                )
                            })}
                        </div>

                        <div className={styles.solutions_block}>
                            {solutions?.map(item => {
                                return (
                                    <p key={item} className={styles.solution}>{item}</p>
                                )
                            })}
                        </div>
                    </div>

                    <div className={styles.image_block}>
                        <div className={styles.image}>
                            <img src={imgPath} alt="illustration" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CaseMainBlock