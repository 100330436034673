import styles from "./cases-column.module.css"

const CasesColumn = ({ title, cases }) => {
    return (
        <div className={styles.industries_column}>
            {title && (
                <h3 className={styles.column_title}>{title}</h3>
            )}
            <div className={styles.column}>
                {cases?.map(item => {
                    return (
                        <div key={item} className={styles.industry}>
                            <span className={styles.circle} />
                            <p className={styles.item_name}>{item}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default CasesColumn