import { message } from "antd"
import CloseIcon from "./img/close.svg"
import SuccessIcon from "./img/success.svg"
import "./ant-message-component.css"
import React from "react"

message.config({ maxCount: 1 })

export const messageSuccess = (text) => {
	message.success({
		content: (
			<div className="content">
				<div className="cross-icon">
					<img src={SuccessIcon} alt="icon" />
				</div>

				<span className="text">{text}</span>

				<div className="close-icon">
					<img onClick={() => message.destroy()} src={CloseIcon} alt="icon" />
				</div>
			</div>
		),
		className: "message message-success"
	})
}