import ContactUs from "../shared/contact-us/contact-us"
import Footer from "../shared/footer/footer"
import MainBlock from "../shared/main-block/main-block"
import MainPageCase from "../shared/main-page-case/main-page-case"
import styles from "./main-page.module.css"

const MainPage = () => {
    const handleClickScroll = () => {
        const element = document.getElementById("cases_block");

        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }

    return (
        <div className={styles.main_page}>
            <div className={styles.content}>
                <div className={styles.main_block}>
                    <MainBlock
                        title="Creating outstanding solutions is our passion"
                        imgPath="/img/global-illustration.svg"
                        backgroundImgPath="/img/main-background-dots-figure.svg"
                        onMoreButtonClick={handleClickScroll}
                        className="main-page"
                    />
                </div>

                <div className="container">
                    {/* <div id="more" className={styles.counts_info_block}>
                        <h3 className={styles.title}>What counts?</h3>
                        <p className={styles.description}>Do you know what the #1 factor that makeds truly acclaimed and successful software companies stand out is? They have some real numbers to back them up, such as:</p>

                        <div className={styles.counts_block}>
                            <div className={styles.count_block}>
                                <div className={styles.count_block_title}>
                                    <div className={styles.title_text_block}>
                                        <p>650</p>
                                        <p>+</p>
                                    </div>
                                    <p className={styles.description_text}>unique talents</p>
                                </div>

                                <p className={styles.count_block_description}>and growing 30% year to year</p>
                            </div>

                            <div className={styles.count_block}>
                                <div className={styles.count_block_title}>
                                    <div className={styles.title_text_block}>
                                        <p>20</p>
                                        <p>years</p>
                                    </div>
                                    <p className={styles.description_text}>on the market</p>
                                </div>

                                <p className={styles.count_block_description}>which helped shape the Techcore we are today</p>
                            </div>

                            <div className={styles.count_block}>
                                <div className={styles.count_block_title}>
                                    <div className={styles.title_text_block}>
                                        <p>8,9</p>
                                        <p>%</p>
                                    </div>
                                    <p className={styles.description_text}>attrition rate</p>
                                </div>

                                <p className={styles.count_block_description}>because passion for great software is in our culture</p>
                            </div>

                            <div className={styles.count_block}>
                                <div className={styles.count_block_title}>
                                    <div className={styles.title_text_block}>
                                        <p>0</p>
                                        <p>clients</p>
                                    </div>
                                    <p className={styles.description_text}>&nbsp;</p>
                                </div>

                                <p className={styles.count_block_description}>and growing 30% year to year</p>
                            </div>
                        </div>
                    </div> */}

                    <div className={styles.cases_block} id="cases_block">
                        <div className={styles.title_block}>
                            <h3 className={styles.title}>Case Studies</h3>
                            {/* <p className={styles.info}>🔥 99% of Fortune 500 companies use our products</p> */}
                        </div>

                        <div className={styles.cases}>
                            <MainPageCase
                                backgroundColor="linear-gradient(116.73deg, #293771 0%, #1D2245 86.96%)"
                                backgroundImagePath="/img/background-illustration-chemwatch.svg"
                                boxShadow="0px 0px 40px #2F3D7B"
                                infoText="The World's leader in Chemical Safety Management"
                                caseName="Chemwatch"
                                classname="chemwatch"
                                cases={[
                                    { amount: 8, text1: "years", text2: "cooperation" },
                                    { amount: 23, text2: "engineers team" },
                                    { amount: 7, text1: "clients", text2: "of the top 20 Fortune 500" }
                                ]}
                                imgPath="/img/main-page-case-illustration-chemwatch.webp"
                                link={{
                                    page: "/web-apps",
                                    block: "chemwatch"
                                }}
                            />

                            <MainPageCase
                                backgroundColor="linear-gradient(116.73deg, #9F2FFF 0%, #5700A2 73.63%)"
                                backgroundImagePath="/img/background-illustration-feaster.svg"
                                infoText="MOBILE CLIENT FOR EVENTS ORGANIZING PLATFORM"
                                caseName="Feaster"
                                classname="feaster"
                                cases={[
                                    { amount: 3, text1: "months", text2: "to MVP" },
                                    { amount: 94, text1: "%", text2: "code sharing iOS/Android" }
                                ]}
                                imgPath="/img/case-illustration-feaster-mobile.webp"
                                imgPosition="center"
                                link={{
                                    page: "/mobile-apps",
                                    block: "feaster"
                                }}
                            />

                            <MainPageCase
                                backgroundColor="linear-gradient(116.55deg, #32D285 4.54%, #019A50 86.96%)"
                                backgroundImagePath="/img/background-illustration-action-planner.svg"
                                infoText="CO2 Credit MARKETPLACE"
                                caseName="EcoCredit"
                                classname="eco_credit"
                                cases={[
                                    { amount: 4, text1: "months", text2: "to  MVP" },
                                    { amount: 4, text2: "engineering team" }
                                ]}
                                imgPath="/img/main-page-case-illustration-eco-credit.webp"
                                imgPosition="center"
                                link={{
                                    page: "/web-apps",
                                    block: "eco-credit"
                                }}
                            />

                            <MainPageCase
                                backgroundColor="linear-gradient(122.07deg, #007AFE -0.26%, #0717A0 105.25%), linear-gradient(116.55deg, #37C1FD 4.54%, #3786FD 75.67%)"
                                backgroundImagePath="/img/background-illustration-i-eat-better.svg"
                                infoText="FOOD DIARY MOBILE APPLICATION"
                                caseName="I Eat Better"
                                classname="i_eat_better"
                                cases={[
                                    { amount: "5M+", text2: "installs" },
                                    { amount: 5, text1: "months", text2: "to market" },
                                    { amount: 92, text1: "%", text2: "code sharing iOS/Android" }
                                ]}
                                imgPath="/img/case-illustration-i-eat-better-mobile.webp"
                                imgPosition="center"
                                link={{
                                    page: "/mobile-apps",
                                    block: "i-eat-better"
                                }}
                            />

                            <MainPageCase
                                backgroundColor="linear-gradient(116.92deg, #756362 0%, #373A4E 100%)"
                                backgroundImagePath="/img/background-illustration-trakopolis.svg"
                                infoText="REAL-TIME TRACKING AND MANAGEMENT OF CORPORATE ASSETS"
                                caseName="Trakopolis"
                                classname="trakopolis"
                                cases={[
                                    { amount: "1,3k", text2: "clients" },
                                    { amount: "100k+", text2: "events tracked everyday" },
                                    { amount: "10+", text2: "integrations with sensors" }
                                ]}
                                imgPath="/img/case-illustration-trakopolis.webp"
                                imgPosition="right"
                                link={{
                                    page: "/web-apps",
                                    block: "trakopolis"
                                }}
                            />

                            <MainPageCase
                                backgroundColor="linear-gradient(129.55deg, #004FD5 -8.39%, #003082 82.91%)"
                                backgroundImagePath="/img/background-illustration-bp-one.svg"
                                infoText="MOBILE APPS SUITE FOR MULTINATIONAL OIL AND GAS COMPANY"
                                caseName="BP One"
                                classname="bp_one"
                                cases={[
                                    { amount: 2, text1: "years", text2: "to revamp 7 apps" },
                                    { amount: "94%", text2: "code sharing iOS/Android" },
                                    { amount: 7, text1: "apps", text2: "created" }
                                ]}
                                imgPath="/img/case-illustration-bp-one-mobile.webp"
                                imgPosition="center"
                                link={{
                                    page: "/mobile-apps",
                                    block: "bp-one"
                                }}
                            />

                            <MainPageCase
                                backgroundColor="linear-gradient(118.8deg, #0098D9 -15.13%, #06AD74 98.55%), linear-gradient(120.42deg, #37C1FD -36.6%, #33D09A 73.72%)"
                                backgroundImagePath="/img/background-illustration-boommy.svg"
                                infoText="FASHION MARKETPLACE PLATFORM"
                                caseName="Boommy"
                                classname="boommy"
                                cases={[
                                    { amount: "50k+", text2: "registered users" },
                                    { amount: 6, text1: "months", text2: "to MVP" }
                                ]}
                                imgPath="/img/main-page-case-illustration-boommy.webp"
                                imgPosition="center"
                                link={{
                                    page: "/web-apps",
                                    block: "boommy"
                                }}
                            />
                        </div>

                        {/* <div className={styles.see_all_cases_button_block}>
                            <Button className={styles.see_all_cases_button}>
                                see all case studies
                                <img src="/img/arrow.webp" alt="arrow-icon" />
                            </Button>
                        </div> */}
                    </div>
                </div>
            </div>

            <ContactUs />
        </div>
    )
}

export default MainPage