
import { useState } from "react"
import { Route, Routes } from "react-router-dom"
import Industries from "../components/industries/industries"
import MainPage from "../components/main-page/main-page"
import MobileApps from "../components/mobile-apps/mobile-apps"
import Services from "../components/services/services"
import Technologies from "../components/technologies/technologies"
import WebApps from "../components/web-apps/web-apps"
import styles from "./navigation.module.css"
import TopMenu from "../components/shared/top-menu/top-menu"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import Footer from "../components/shared/footer/footer"

const Navigation = () => {
    const [navigateTrigger, setNavigateTrigger] = useState()

    return (
        <>
            <TopMenu />
            <div className={styles.application}>
                <TransitionGroup>
                    <CSSTransition
                        key={navigateTrigger}
                        classNames="fade"
                        timeout={1000}
                    >
                        <Routes>
                            <Route exact path="/" element={<MainPage />} />
                            <Route exact path="/web-apps" element={<WebApps />} />
                            <Route exact path="/mobile-apps" element={<MobileApps />} />
                            <Route exact path="/technologies" element={<Technologies />} />
                            <Route exact path="/industries" element={<Industries />} />
                            <Route exact path="/services" element={<Services />} />
                        </Routes>
                    </CSSTransition>
                </TransitionGroup>
            </div>

            <Footer navigateTrigger={setNavigateTrigger} />
            {/* <div className={styles.mobile}>
                <p>Sorry, mobile version is under development.</p>
                <p>Please use your PC or laptop to visit our site.</p>
            </div> */}
        </>
    )
}

export default Navigation