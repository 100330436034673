// import TopMenu from "../top-menu/top-menu"
import CasesColumn from "./cases-column/cases-column"
import styles from "./industry-services.module.css"

const IndustryServices = ({ title, description, casesColumnsInfo }) => {
    return (
        <div className={styles.main_block}>
            <img className={styles.background_pattern} src="/img/main-background-dots-right.svg" alt="pattern-img" />
            <div className={styles.wrapper}>
                {/* <TopMenu /> */}

                <div className="container">
                    <div className={styles.content}>
                        <h1 className={styles.title}>{title}</h1>
                        {description && (
                            <p className={styles.description}>{description}</p>
                        )}
                        <div className={styles.industries_block}>
                            {casesColumnsInfo.map((caseColumn, index) => {
                                const { title, cases } = caseColumn

                                return (
                                    <CasesColumn
                                        key={`case-column-${index}`}
                                        title={title}
                                        cases={cases}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IndustryServices