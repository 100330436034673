import { Button } from "antd"
// import TopMenu from "../top-menu/top-menu"
import styles from "./main-block.module.css"

const MainBlock = ({
    title,
    imgPath,
    // withTopMenu = true,
    description,
    backgroundImgPath,
    onMoreButtonClick,
    className
}) => {
    return (
        <div className={`${styles.main_block_component} ${className}`}>
            <img className={styles.background_pattern} src={backgroundImgPath} alt="pattern-img" />
            <div className={styles.wrapper}>
                {/* {withTopMenu && (
                    <TopMenu />
                )} */}

                <div className={`${styles.content} container`}>
                    <div className={styles.main_block}>
                        <div className={styles.title_block}>
                            <h1 className={styles.title}>{title}</h1>

                            {description && (
                                <p className={styles.description}>{description}</p>
                            )}
                        </div>

                        <img className={styles.illustration} src={imgPath} alt="illustration" />
                    </div>

                    <Button onClick={onMoreButtonClick} className={styles.discover_more_button}>
                        <img src="/img/mouse-icon.svg" alt="mouse-icon" />
                        discover more
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default MainBlock